

import {IUserServicePriceResponse} from '@/services/dto/user/IUserServicePriceResponse';

import {Component} from 'vue-property-decorator';
import {IAccountCreation, IAccountCreationResponse, IEmailCreation} from '@/services/dto/user/ICreateUser';
import SigStepNav from '@/components/user/SigStepNav.vue';
import UserCreationStep3 from '@/components/user/UserCreationStep3.vue';
import UserCreationStep2 from '@/components/user/UserCreationStep2.vue';
import UserCreationStep1 from '@/components/user/UserCreationStep1.vue';
import {services} from '@/main';
import UserCreationStep3Print from '@/components/user/UserCreationStep3Print.vue';
import {INavigationStep} from '@/services/components/INavigationStep';
import UserCreationService from '@/services/UserCreationService';
import AbstractComponentVue from '@/services/AbstractComponentVue';
import AlertService from '@/utils/AlertService';
import ErrorUtils from '@/utils/ErrorUtils';

const userCreationService = new UserCreationService();

@Component({
  components: {
    UserCreationStep3Print,
    UserCreationStep1,
    UserCreationStep2,
    UserCreationStep3,
    SigStepNav,
  },
})
export default class UserCreation extends AbstractComponentVue {

  // Creation des donnés de navigation
  private navSteps: INavigationStep = userCreationService.initNav();

  private userSrpDto!: IUserServicePriceResponse;

  private accountCreation!: IAccountCreation;

  private entitySelected: boolean = false;

  /* Savoir si la validation en attente pour l'email */
  private addressMailValidationRequested: boolean = false;

  private accountCreationResponse!: IAccountCreationResponse;

  /**
   * Méthode lors de la création du composant.
   */
  private created() {
    this.accountCreation = {
      login: '',
      entityName: '',
      lastName: '',
      firstName: '',
      admin: false,
      roleType: 'ROLE_SELFCARE_COLLABORATEUR',
      email: null,
    };
    // load PriceForUserService
    this.updateCurrentStep(1);
    this.loadPriceForUserService();
  }

  /* -----------------------------------------------------------------------------
   *  Action Creation utilisateur
   ----------------------------------------------------------------------------- */

  /**
   * Callback utilisé dans l'appele de la création de l'utilisateur
   * @param userSrpDto utilisé dans le composant step2
   * @param newMail information saisie dans le composant step2
   */
  private callCreateWebService(newMail: IEmailCreation, userSrpDto: IUserServicePriceResponse) {
    this.showModal('modal-traitement-cours');
    const accountCreation = {
      admin: this.accountCreation.admin,
      firstName: this.accountCreation.firstName,
      lastName: this.accountCreation.lastName,
      entityName: this.accountCreation.entityName,
      roleType: this.entitySelected ? 'ROLE_ENTITY' : this.accountCreation.roleType,
      login: this.accountCreation.login,
      email: newMail,
    };

    userCreationService.createAccount(accountCreation)
        .then((response: any) => {
          if (response.status !== undefined) {
            const accountCreationResponse: IAccountCreationResponse = {
              message: response.data.message,
              activationCode: response.data.activationCode,
            };
            if (response.status === 202) {
              this.addressMailValidationRequested = true;
            }
            this.completeCreationStep3(accountCreation, accountCreationResponse);
          } else {
            console.error(response);
            AlertService.error(ErrorUtils.msgDefault);
          }
        })
        .catch(((res: any) => {
          AlertService.error(ErrorUtils.msgDefault);
        }))
        .finally(() => {
          this.hideModal('modal-traitement-cours');
        });
  }

  /**
   * Chargement le prix d'un service mail dans la userSrpDto (utile pour étape 2)
   * @private
   */
  private loadPriceForUserService() {
    userCreationService.getPriceForUserServiceMail().then((response: any) => {
      this.userSrpDto = response.data;
    }).catch(((res: any) => {
      console.error(res);
    }));
  }

  private completeCreationStep3(account: IAccountCreation, response: IAccountCreationResponse) {
    this.accountCreationResponse = response;
    this.accountCreation = account;
    this.updateCurrentStep(3);
    this.navSteps.steps.forEach((step): any => {
      step.disabled = true;
    });
  }

  private completeCreationStep2(account: IAccountCreation, isEntity: boolean) {
    this.accountCreation = account;
    this.entitySelected = isEntity;
    this.updateCurrentStep(2);
  }

  private completeCreationStep1(account: IAccountCreation, isEntity: boolean, email: IEmailCreation){
    this.accountCreation = account;
    this.entitySelected = isEntity;
    this.callCreateWebService(email, this.userSrpDto);
  }

  private cancelToStep1() {
    this.updateCurrentStep(1);
  }

  private cancelButton() {
    services.routageService.goTo('/account/list');
  }

  /**
   * Changement d'onglet
   * @param newStep
   */
  private updateCurrentStep(newStep: number) {
    this.navSteps.currentstep = newStep;
  }

  /***
   * Permet d'ajouter une class bootstrap qui cache le fromulaire de l'utilisateur afin de personalisé l'impression
   * voir UserCreationStep3Print
   * @private
   */
  private hideDivPrintIfFinalStep(): string {
    return this.navSteps.maxstep === this.navSteps.currentstep ? ' d-print-none' : ' ';
  }

  private get roleType(): string {
    return this.accountCreation.roleType;
  }
}
