
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
})
export default class UserCreationStep3Print extends Vue {
  @Prop() private activationCode!: string;
  @Prop() private merUrl!: string;
  @Prop() private mail!: string;
  @Prop() private login!: string;
}
