
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';

@Component({})
export default class SigSelect extends Vue {
  @Prop({required: true}) private value!: ISigInputTextDto;
  @Prop({required: true}) private divId!: string;
  @Prop({required: true}) private options!: [];
  @Prop({required: false}) private disabled?: boolean;
  @Prop() private mandatory!: boolean;
  @Prop() private pattern!: string | undefined;
  @Prop() private overideMsgPattern !: string | undefined;
  @Prop() private label!: string | undefined;
  @Prop() private contentColsLg!: number;
  @Prop() private labelColsLg!: number;
  @Prop({default: ''}) private tooltip!: string;
  private currentSelect: ISigInputTextDto = {
    value: this.value.value,
    hasError: this.value.hasError,
    msgError: this.value.msgError,
  };

  public get val(): ISigInputTextDto {
    return this.currentSelect;
  }

  public error(msg: string): void {
    this.currentSelect.msgError = msg;
    this.currentSelect.hasError = true;
  }

  public setValue(val: string | null): void {
    if (val === undefined || val === null) {
      this.currentSelect.value = '';
    } else {
      this.currentSelect.value = val;
    }
    this.watchCurrentSelectVal();
  }

  @Watch('currentSelect.value')
  private watchCurrentSelectVal() {
    if (this.mandatory
        && (this.currentSelect.value === null || this.currentSelect.value === undefined
            || this.currentSelect.value.length === 0)) {
      this.currentSelect.hasError = true;
      this.currentSelect.msgError = 'Ce champ est obligatoire.';
    } else {
      this.currentSelect.hasError = false;
      this.currentSelect.msgError = '';
    }
    this.$emit('watchvalue', this.currentSelect, this.divId);
  }

}
