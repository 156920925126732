import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';

export default class InputValidationUtils {

	public static msgAdresseNotAutorized: string = 'Les adresses email se terminant en \'notaires.fr\' ne sont pas autorisées';


	/**
	 * validation ISigInputTextDto
	 * @param input
	 * @param mandatory
	 * @param minlength
	 * @param maxlength
	 * @param pattern
	 * @param overideMsgPattern
	 * @param notairesFrUnauthorized
	 */
	public static validationInputText(
		input: ISigInputTextDto,
		mandatory: boolean,
		minlength: number | undefined,
		maxlength: number | undefined,
		pattern: string | undefined,
		overideMsgPattern: string | undefined,
		notairesFrUnauthorized: boolean,
	) {

		if (mandatory && input.value.trim().length === 0) {
			input.msgError = 'Ce champ est obligatoire.';
			input.hasError = true;
		} else if (notairesFrUnauthorized && this.hasNotairesFr(input.value)) {
			input.hasError = true;
			input.msgError = InputValidationUtils.msgAdresseNotAutorized;
		} else if (pattern !== undefined && !new RegExp(pattern).test(input.value.trim())) {
			input.msgError = overideMsgPattern !== undefined ? overideMsgPattern : 'Ce champ comporte des caractères interdits.';
			input.hasError = true;
		} else if (minlength !== undefined && input.value.trim().length < minlength) {
			input.msgError = 'Ce champ doit comporter au moins ' + minlength + ' caractère(s).';
			input.hasError = true;
		} else if (maxlength !== undefined && input.value.trim().length > maxlength) {
			input.msgError = 'Ce champ doit être inférieur ou égal à ' + maxlength + ' caractère(s).';
			input.hasError = true;
		} else {
			input.msgError = '';
			input.hasError = false;
		}
		return !input.hasError;
	}

	public static hasNotairesFr(val: string): boolean {
		const extNotairesfr: string = 'notaires.fr';
		return val.length > 9 && val.indexOf(extNotairesfr, val.length - extNotairesfr.length) !== -1;
	}
}
