
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import SigH4Title from '@/components/user/SigH4Title.vue';
import {IAccountCreation} from '@/services/dto/user/ICreateUser';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import {services, store} from '@/main';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import AbstractComponentVue from '@/services/AbstractComponentVue';
import UserCreationService from '@/services/UserCreationService';
import LoginUtils from '@/utils/LoginUtils';
import PatternUtils from '@/utils/PatternUtils';
import SigSelect from '@/components/user/SigSelect.vue';
import Vue, { isShallow } from 'vue';
import { IUserServicePriceResponse } from '@/services/dto/user/IUserServicePriceResponse';

const userCreationService = new UserCreationService();

@Component({
  components: {SigSelect, SigInputTexte, SigH4Title},
})
export default class UserCreationStep1 extends AbstractComponentVue {
  private patternUtils = PatternUtils;
  @Ref('refFonction') private refFonction!: SigSelect;
  @Ref('refEntityName') private refEntityName?: SigInputTexte;
  @Ref('refFirstName') private refFirstName?: SigInputTexte;
  @Ref('refLastName') private refLastName?: SigInputTexte;
  @Ref('loginGenerated') private loginGenerated?: SigInputTexte;

  @Prop({required: true}) private accountCreation!: IAccountCreation;
  @Prop({required: true}) private entitySelected!: boolean;


  private isEntity: boolean = false;
  private entityName: ISigInputTextDto = {value: '', hasError: true, msgError: ''};
  private firstName: ISigInputTextDto = {value: '', hasError: true, msgError: ''};
  private lastName: ISigInputTextDto = {value: '', hasError: true, msgError: ''};
  private login: ISigInputTextDto = {value: '', hasError: true, msgError: ''};
  private fonction: ISigInputTextDto = {value: 'ROLE_SELFCARE_COLLABORATEUR', hasError: false, msgError: ''};

  private admin: boolean = false;

  private get radioAdminShow(): boolean {
    return this.fonction.value !== 'ROLE_SELFCARE_NOTAIRE';
  }

  private selectFunctionOptions = [
    {text: 'Collaborateur', value: 'ROLE_SELFCARE_COLLABORATEUR'},
    {text: 'Notaire', value: 'ROLE_SELFCARE_NOTAIRE'},
  ];

  /**
   * Condition d'activation du bouton de l'étape 2. Messagerie
   */
  private disabledButtonToStep2(): boolean {
    const notValidChamps: boolean[] = [];
    notValidChamps.push(this.login.hasError);
    if (this.isEntity) {
      notValidChamps.push(this.entityName.hasError);
    } else {
      notValidChamps.push(this.firstName.hasError);
      notValidChamps.push(this.lastName.hasError);
    }
    // Attention à la négation
    return notValidChamps.some((x) => x);
  }


  /* -----------------------------------------------------------------------------
   *  Validation - watch des champs
   ----------------------------------------------------------------------------- */

  /**
   * watcher prenom
   * @param childVal
   */
  private watchFistName(childVal: ISigInputTextDto) {
    this.firstName = childVal;
    this.login.value = this.updateUserLogin();
    this.updateLogin();
  }

  /**
   * watcher nom
   * @param childVal
   */
  private watchLastName(childVal: ISigInputTextDto) {
    this.lastName = childVal;
    this.updateLogin();
  }

  /**
   * watcher identifiant
   * @param childVal
   */
  private watchEntitylogin(childVal: ISigInputTextDto) {
    this.entityName = childVal;
    this.updateLogin();
  }

  /**
   * watcher identifiant
   */
  private updateLogin() {
    if (this.loginGenerated?.val !== undefined) {
      const newLoginGenerated: string = this.updateUserLogin();
      this.loginGenerated.setValue(newLoginGenerated);
    }
  }

  /**
   * watcher fonction
   */
  private watchFunction(childVal: ISigInputTextDto) {
    this.fonction.value = childVal.value;
    this.updateLogin();
  }

  /**
   * watcher entité
   */
  @Watch('isEntity')
  private watchisEntity() {
    const isEmpty: boolean = this.isEntity ? this.entityName.value.length > 0
        : this.lastName.value.length > 0 && this.firstName.value.length > 0;
    if (isEmpty) {
      this.validAllFormStep1();
      this.updateLogin();
    } else {
      this.loginGenerated?.setValueWithoutValidation({value: '', hasError: true, msgError: ''});
    }
  }

  private watchLogin(childVal: ISigInputTextDto) {
    this.login = childVal;
  }

  /**
   * Erreur de validation, login déjà utilisé
   */
  private validationAlreadyLoginUsed() {
    this.login.hasError = true;
    this.login.msgError = 'L\'identifiant que vous avez choisi existe déjà. Veuillez en choisir un autre.';
  }

  /**
   *  Mise à jour de login (en cas de changement dans des informations d'identité de l'utilisateur)
   */
  private updateUserLogin(): string {
    const role: string = this.isEntity ? 'ROLE_ENTITY' : this.fonction.value;
    // cas pas de construction de login car formulaire en erreur
    const isEntityAndEntityNameError = role === 'ROLE_ENTITY' && this.entityName.hasError;
    const isNotEntityAndNamesError = role !== 'ROLE_ENTITY' && (this.firstName.hasError || this.lastName.hasError);
    if (isEntityAndEntityNameError || isNotEntityAndNamesError) {
      return '';
    } else {
      // cas nominal construction de login
      return LoginUtils.getDefaultNamingConvention(role, this.entityName.value.trim(),
          this.firstName.value.trim(), this.lastName.value.trim(), store.getters.crpcen);
    }
  }

  /**
   * Chargement de la validation du formulaire de l'étape 1  step 1 (champs : login, prenom, nom, nom de l'entity)
   */
  private validAllFormStep1() {
    if (this.loginGenerated !== undefined) {
      this.loginGenerated.watchCurrentInputVal();
    }
    if (this.isEntity) {
      if (this.refEntityName !== undefined) {
        this.refEntityName.watchCurrentInputVal();
      }
    } else {
      if (this.refFirstName !== undefined) {
        this.refFirstName.watchCurrentInputVal();
      }
      if (this.refLastName !== undefined) {
        this.refLastName.watchCurrentInputVal();
      }
    }
  }

  /**
   * Check page validation
   * @private
   */
  private checkIfLoginAlreadyUsed() {
    const login = this.login.value;
    this.showModal('modal-traitement-cours');
    userCreationService.checkLoginAlreadyUsed(login).then((response: any) => {
      if (response.status === undefined || response.status !== 200) {
        console.error(String(response));
        this.validationAlreadyLoginUsed();
      } else {
        // GO to page 2
        this.loadStepMessagerieStep2();
      }
      this.hideModal('modal-traitement-cours');
    }).catch(((res: any) => {
      console.error(res);
      this.validationAlreadyLoginUsed();
      this.hideModal('modal-traitement-cours');
    }));
  }

  private loadStepMessagerieStep2() {
    const accountCreation: IAccountCreation = {
      admin: this.admin,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      entityName: this.isEntity ? this.entityName.value : '',
      roleType: this.fonction.value,
      login: this.login.value,
      email: {
        addressPrefix: this.updateUserLogin(),
        addressSuffix: '',
        contractAccepted: false,
        smartphoneActive: !this.isEntity,
        webmailActive: true,
      },
    };
    if(Vue.prototype.isAdnov() && !Vue.prototype.isAll()){
      this.$emit('void-next-step', accountCreation, this.isEntity, undefined);
    }else{
      this.$emit('gotostep2-ok', accountCreation, this.isEntity);
    }
  }

  private cancelButton() {
    services.routageService.goTo('/');
    this.$emit('cancel-button');
  }
}
