
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import InputValidationUtils from '@/utils/InputValidationUtils';

const inputUtils = new InputValidationUtils();

@Component({})
export default class SigInputTexte extends Vue {
  @Prop() private value?: ISigInputTextDto;
  @Prop({required: true}) private divId!: string;
  @Prop() private mandatory!: boolean;
  @Prop() private maxlength!: number | undefined;
  @Prop() private minlength!: number | undefined;
  @Prop() private pattern!: string | undefined;
  @Prop() private overideMsgPattern !: string | undefined;
  @Prop() private label!: string | undefined;
  @Prop() private contentColsLg!: number;
  @Prop() private labelColsLg!: number;
  @Prop() private placeholder?: string;
  @Prop({default: 'text'}) private type!: string;
  @Prop({default: false}) private notairesFrUnauthorized!: boolean;
  @Prop({default: ''}) private tooltip!: string;
  @Prop({default: ''}) private autocomplete!: string;
  private currentInput: ISigInputTextDto = {
    value: this.value ? (this.value.value ? this.value.value : '' ) : '',
    hasError: this.value ? this.value.hasError : true,
    msgError: this.value ? this.value.msgError : '',
  };

  public get val(): ISigInputTextDto {
    return this.currentInput;
  }

  public error(msg: string): void {
    this.currentInput.msgError = msg;
    this.currentInput.hasError = true;
  }

  public setValue(val?: string | null): void {
    if (val === undefined || val === null) {
      this.currentInput.value = '';
    } else {
      this.currentInput.value = val;
    }
    this.watchCurrentInputVal();
  }

  public setValueWithoutValidation(val?: ISigInputTextDto): void {
    if (val) {
      this.currentInput = val;
    }
  }

  public watchCurrentInputVal() {
    InputValidationUtils.validationInputText(this.currentInput, this.mandatory, this.minlength, this.maxlength,
        this.pattern, this.overideMsgPattern, this.notairesFrUnauthorized);
    this.$emit('watchvalue', this.currentInput, this.divId);
  }

}
